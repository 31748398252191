import React from "react";
import { graphql, PageProps } from "gatsby";

import BlogHero from "@components/blog/blogHero";
import { BlocksContent } from "@components/global";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import ColumnContent from "@components/global/columnContent";
import { Query } from "@graphql-types";
import { Container } from "@util/standard";
import { isSanityBlogPreview, isSanityCategoryPanelPreview, isSanityColumn, isSanityColumnContent } from "@util/types";

import BlogPreview from "@components/global/blogPreview";
import Hero from "@components/global/hero";
import SEO from "@shared/seo";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function FeedYourLifeInnerTemplate({ data }: Props) {
  const blog = data.allSanityFeedYourLifeInner.nodes[0];

  return (
    <>
      {blog.seo && <SEO seoData={blog.seo} slug={`feed-your-life/${blog.slug?.current}`} />}
      <Layout>
        {blog.hero && <Hero data={blog.hero} />}
        {blog.pageContent &&
          blog.pageContent.map(data => {
            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} />;
            }

            if (isSanityCategoryPanelPreview(data)) {
              return <CategoryPanelPreview data={data} key={data._key} />;
            }

            if (isSanityColumn(data)) {
              return (
                <Container width="65%" tabletWidth="80%" margin="100px auto" key={data._key}>
                  <BlocksContent blocks={data._rawColumnContent} />
                </Container>
              );
            }

            if (isSanityBlogPreview(data)) {
              return (
                <Container flexDirection="column" margin="0 auto 15px auto">
                  <BlogPreview data={data} key={data._key} />
                </Container>
              );
            }
            return;
          })}
      </Layout>
    </>
  );
}

export const query = graphql`
  query feedYourLifeInnerPageQuery($slug: String) {
    allSanityFeedYourLifeInner(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        pageName
        slug {
          current
        }
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        pageContent {
          ... on SanityColumnContent {
            ...sanityColumnContent
          }
          ... on SanityColumn {
            ...sanityColumn
          }
          ... on SanityCategoryPanelPreview {
            ...sanityCategoryPanelPreview
          }
          ... on SanityBlogPreview {
            ...sanityBlogPreview
          }
        }
      }
    }
  }
`;

export default FeedYourLifeInnerTemplate;
