import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SanityBlog } from "@graphql-types";
import { gradients, icons, MOBILE_BREAKPOINT } from "@util/constants";
import { Container, H4, A, Gradient, P } from "@util/standard";
import { Button, Image, Video } from ".";
import ModalBox from "./modalBox";
import { useCheckScreenWidth } from "@util/hooks";
import { useStore } from "@state/store";
import { handleSaveBlog } from "@util/helper";
import { toast } from "react-toastify";

interface Props {
  data: SanityBlog;
  width?: string;
  margin?: string;
  blogId: string;
}

const PlayButton = styled.img<{ hoverOn?: boolean }>`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  ${({ hoverOn }) =>
    hoverOn &&
    ` width: 80px;
    height: 80px;`}

  transition: all 0.2s;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 67px;
    height: 67px;
    top: 27%;
    transform: translate(-50%, -27%);
  }
`;

const SaveIcon = styled.img`
  width: 14px;
  height: 20px;
  margin: auto;
`;

function BlogCard({ data, width, margin, blogId }: Props) {
  const [isSaved, setIsSaved] = useState<boolean>();
  const [hoverPlay, setHoverPlay] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { user, savedBlogs, isLoggedIn } = useStore();

  const { isMobileWidth } = useCheckScreenWidth();

  const blogMatch = () => {
    const blogMatches = savedBlogs?.filter(blog => blog === blogId);
    blogMatches && blogMatches.length > 0 ? setIsSaved(true) : setIsSaved(false);
  };
  const checkSaved = () => {
    if (!isLoggedIn) {
      useStore.setState({ authMenuOpen: true });
    }
    if (user == null || blogId == null || user.uid == null) {
      toast.error("Something went wrong when trying to save this article");

      return null;
    }
    handleSaveBlog({
      id: blogId,
      savedBlogs: savedBlogs ? savedBlogs : [],
      isUserSignedIn: true,
      userUId: user.uid,
    });
    toast.success(isSaved ? "Article unsaved" : "Article Saved!");
    return;
  };

  useEffect(() => {
    blogMatch();
  });
  useEffect(() => {
    setHoverPlay(false);
  }, [popUpVisible]);

  return (
    <Container
      width={width}
      margin={margin}
      position="relative"
      onMouseEnter={() => setHoverPlay(true)}
      onMouseLeave={() => setHoverPlay(false)}
    >
      <Container
        width={width}
        position="relative"
        cursor={data.blogVideo && data.blogVideo.url ? "pointer" : "default"}
        onClick={() => setPopUpVisible(true)}
      >
        <Image
          isBackground
          image={data.featureImage?.asset?.gatsbyImageData}
          minHeight={isMobileWidth ? "400px" : "550px"}
        />
        <Container width="100%" height="100%" zIndex={3}>
          {data.blogVideo && data.blogVideo.url && <PlayButton src={icons.playButton} hoverOn={hoverPlay} />}
          <Container
            flexDirection="column"
            position="absolute"
            bottom={isMobileWidth ? "20px" : "40px"}
            left={isMobileWidth ? "20px" : "40px"}
            width="85%"
          >
            <Container>
              <A cursor="default" margin="0 10px 0 0 " color="white">
                {data.category?.name?.toUpperCase()}
              </A>
              {data.subCategory && data.subCategory[0] && (
                <P noMargin color="white">
                  |
                </P>
              )}
              {data.subCategory && data.subCategory[0] && (
                <A cursor="default" margin="0 0 0 10px" color="white">
                  {data.subCategory[0]?.name?.toUpperCase()}
                </A>
              )}
            </Container>
            <H4 margin="5px 0 22px 0" fontWeight="normal" width={isMobileWidth ? "100%" : "87%"} color="white">
              {data.blogTitle}
            </H4>
            <Container zIndex={4}>
              <Button
                theme="borderedWhite"
                text={data.blogVideo?.url ? "Watch" : "Learn"}
                linkTo={("/learn/" + data.slug?.current) as string}
              />
              {data.category && data.category.name && (
                <Container margin="auto 0">
                  <Button
                    theme="textWhite"
                    linkTo={`/learn/${data.category.name.toLowerCase()}`}
                    text={`More ${data.category?.name?.toLowerCase()}`}
                    padding="0 20px"
                  />
                </Container>
              )}
            </Container>
          </Container>
        </Container>
        <Container width="100%" height="100%" position="absolute">
          {hoverPlay && !popUpVisible && data.blogVideo && data.blogVideo.url && (
            <Video
              url={data.blogVideo.url as string}
              id={data.blogVideo.url as string}
              width="300%"
              background
              hidePlayButton
            />
          )}
        </Container>

        <Gradient gradient={gradients.gradientFromBottom} />
      </Container>
      <Container
        onClick={() => checkSaved()}
        cursor="pointer"
        backgroundColor={isSaved ? "wanakaBlue" : "pink"}
        width="50px"
        height="50px"
        position="absolute"
        top="0px"
        right="0px"
        zIndex={3}
      >
        <SaveIcon src={isSaved ? icons.saved : icons.unSaved} />
      </Container>
      {data.blogVideo && data.blogVideo.url && (
        <ModalBox isVideo visible={popUpVisible} setVisible={setPopUpVisible}>
          {popUpVisible && data.blogVideo && (
            <Video
              controls
              url={data.blogVideo.url as string}
              id={data.blogVideo.url as string}
              hidePlayButton
              volume
              autoPlayOnLoad
            />
          )}
        </ModalBox>
      )}
    </Container>
  );
}

export default BlogCard;
