import { SanityBlogPreview } from "@graphql-types";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import React from "react";
import styled from "styled-components";
import BlogCard from "./blogCard";

interface Props {
  data: SanityBlogPreview;
}

const ScrollContainer = styled.div`
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    overflow-x: scroll;
    width: 96%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

function BlogPreview({ data }: Props) {
  if (data == null) {
    return null;
  }

  return (
    <ScrollContainer>
      <Container width="98%" margin="auto" tabletWidth="max-content" tabletMargin="0 0 0 4%" flexDirection="row">
        {data.blogRefs?.map((blog, index) => {
          if (blog == null || data.blogRefs == null) {
            return null;
          }

          const isLast = index === data.blogRefs.length - 1;

          return (
            <Container width="100%" tabletWidth="400px" mobileWidth="84vw" key={blog._id}>
              <BlogCard blogId={blog._id} data={blog} width="100%" margin={isLast ? "15px 0 0 0 " : "15px 15px 0 0"} />
            </Container>
          );
        })}
      </Container>
    </ScrollContainer>
  );
}

export default BlogPreview;
